import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import { GetClientProfileById } from "../../../Services/Api/Client/customerAuth";
import { Helmet } from "react-helmet-async";
import {
  CloseCredit,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  CloseCreditRequestService,
  GetCustomercreditRequestPaginate,
} from "../../../Services/Api/Client/customerCreditRequest";
import format from "date-fns/format";
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";
import {
  DailyPenalty,
  WeeklyPenalty,
  FortNightPenalty,
} from "../../../Hooks/CalculatePenalty";
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";
import Select from "react-select";

const statusArr = [
  { label: "All", value: -1 },
  { label: "Pending", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Rejected", value: 2 },
  { label: "Closed", value: 3 },
];

const CreditRequest = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerCreditRequest, setCustomerCreditRequest] = useState([]);
  const [depositOption, setDepositOption] = useState([]);
  const [count, setCount] = useContext(ClientProfileCompletion);

  const handleFilterData = (status) => {
    setPage(0);
    getCustomerCreditRequestList(limit, page, status.value);
  };

  const getCustomerCreditRequestList = async (
    limitO = "",
    pageO = "",
    status = -1
  ) => {
    setIsLoading(true);
    await GetCustomercreditRequestPaginate({
      limit: limitO,
      page: pageO,
      status: status,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerCreditRequest(res?.data?.data?.credit_request || {});
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerCreditRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerCreditRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getProfileData = async () => {
    GetClientProfileById()
      .then((res) => {
        setCount(res?.data?.data.profile_completed_percentage);
        const items = JSON.parse(localStorage.getItem("clientUserData"));
        const data = {
          ...items,
          profile_completed_percentage:
            res?.data?.data.profile_completed_percentage,
        };
        localStorage.setItem("clientUserData", JSON.stringify(data));
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions || {});
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };
  const closeCreditRequest = async (fileId) => {
    CloseCredit("Close Credit ?").then(async (confirm) => {
      if (confirm.isConfirmed) {
        const postData = {
          id: fileId?.id,
          user_id: fileId?.user_id,
          comments: confirm.value,
        };
        await CloseCreditRequestService(postData)
          .then(async (res) => {
            SuccessToast(
              res?.data?.message || "Credit Request Closed successfull."
            );
            getCustomerCreditRequestList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    getDepositOption();
  }, []);

  useEffect(() => {
    getProfileData();
    getCustomerCreditRequestList(limit, page);
  }, [limit, page]);

  const checkProfile = (count) => {
    if (count !== 100) {
      ErrorToast("Please complete your Profile 100%");
      navigate("/client/creditrequest");
    } else {
      navigate("/client/addcreditrequest");
    }
  };

  return (
    <>
      <Helmet>
        <title>CPay | Customer Credit Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="col">
                    <h3 className="page-title">Credit Request</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/client/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Credit Request</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="multipleSelection border-selection">
                    <Select
                      options={statusArr}
                      defaultValue={statusArr[0]}
                      onChange={(e) => handleFilterData(e)}
                    ></Select>
                  </div>
                </div>
                <div className="col-md-4">
                  {depositOption?.credit === 1 && (
                    <div
                      className="col-right "
                      style={{ float: "right" }}
                      onClick={() => checkProfile(count)}
                    >
                      {/* <Link to="/client/addcreditrequest" className="btn btn-primary me-1">
                      <i className="fas fa-plus" /> Request Credit Amount
                    </Link> */}

                      <button className="btn btn-primary me-1">
                        <i className="fas fa-plus" /> Request Credit Amount
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Status</th>
                          <th>Reference Number</th>
                          <th>Amount</th>
                          <th>Used Credit Amount</th>
                          <th>Remaining Credit Amount</th>
                          <th>Paid Credit Amount</th>
                          <th>Reason</th>
                          <th>Due Date</th>
                          <th>Penalty Start Date</th>
                          <th>Late Payment Fees</th>
                          <th>Comment</th>
                          <th>Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerCreditRequest.map((customer, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {customer.status === 1 && (
                                    <span className="badge badge-pill bg-success-light">
                                      Approved
                                    </span>
                                  )}
                                  {customer.status === 0 && (
                                    <span className="badge badge-pill bg-warning-light">
                                      Pending
                                    </span>
                                  )}
                                  {customer.status === 2 && (
                                    <span className="badge badge-pill bg-danger-light">
                                      Rejected
                                    </span>
                                  )}
                                  {customer.status === 3 && (
                                    <span className="badge badge-pill bg-info-light">
                                      Closed
                                    </span>
                                  )}
                                </td>
                                {customer?.reference_number ? (
                                  <td>
                                    {customer?.reference_number}
                                    <br />
                                    <span>
                                      {format(
                                        new Date(customer?.createdAt),
                                        "dd MMMM, yyyy, hh:mm a"
                                      )}
                                    </span>
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.currency}{" "}
                                  </span>{" "}
                                  {(customer?.amount || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.currency}{" "}
                                  </span>
                                  {(customer?.amount_due || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer.currency}{" "}
                                  </span>{" "}
                                  {(customer?.remaining_amount || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer.currency}{" "}
                                  </span>{" "}
                                  {(customer?.amount_paid || 0).toFixed(2)}
                                </td>
                                {customer?.reason ? (
                                  <td>{customer?.reason}</td>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.due_date ? (
                                  <td>
                                    {format(
                                      new Date(customer?.due_date),
                                      "dd MMMM, yyyy"
                                    )}
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.penalty_start_date ? (
                                  <td>
                                    {format(
                                      new Date(customer?.penalty_start_date),
                                      "dd MMMM, yyyy"
                                    )}
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.paidAt == null &&
                                customer?.penalty_start_date != null ? (
                                  <>
                                    {customer?.penalty_period === "DAILY" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <DailyPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                    {customer?.penalty_period === "WEEKLY" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <WeeklyPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                    {customer?.penalty_period ===
                                      "FORT_NIGHT" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <FortNightPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.status_comments ? (
                                  <td>{customer?.status_comments}</td>
                                ) : (
                                  <td>-</td>
                                )}
                                <td>
                                  {customer?.amount -
                                    customer?.remaining_amount >
                                  0 ? (
                                    <>
                                      {customer?.status === 1 ? (
                                        <>
                                          {customer?.payment_order_no ==
                                          null ? (
                                            <>
                                              {customer?.payment_request_id ==
                                              null ? (
                                                <>
                                                  -
                                                  {/* {customer?.amount_paid <
                                                  customer?.amount -
                                                    customer?.remaining_amount ? (
                                                    <>
                                                      <Link
                                                        to="/client/pay-credit"
                                                        state={{
                                                          id: customer.id,
                                                        }}
                                                        className="btn btn-sm btn-white text-success me-2"
                                                      >
                                                        Pay Credit
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    "-"
                                                  )} */}
                                                </>
                                              ) : (
                                                <>
                                                  {customer?.payment_request
                                                    ?.status === 0 && (
                                                    <span className="badge badge-pill bg-warning-light">
                                                      Pending
                                                    </span>
                                                  )}
                                                  {customer?.payment_request
                                                    ?.status === 2 && (
                                                    <>
                                                      <span>
                                                        Rejected:{" "}
                                                        {
                                                          customer
                                                            ?.payment_request
                                                            ?.status_comments
                                                        }
                                                      </span>
                                                      <br />
                                                      <Link
                                                        to="/client/edit-pay-credit"
                                                        state={{
                                                          id: customer.id,
                                                        }}
                                                        className="btn btn-sm btn-white text-success me-2 my-2"
                                                      >
                                                        Edit
                                                      </Link>
                                                    </>
                                                  )}
                                                  {customer?.payment_request
                                                    ?.status === 1 && "-"}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {(customer?.status === 1 && customer?.amount_due === 0) ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              closeCreditRequest({
                                                id: customer?.id,
                                                user_id: customer?.user_id,
                                              })
                                            }
                                            className="btn btn-sm btn-white text-danger me-2 "
                                          >
                                            Close Credit
                                          </button>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {customerCreditRequest.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={13}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={13}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditRequest;
